import * as actionTypes from 'store/actions/colors';

import * as moment from 'moment';

const initialState = {
        colors: [],
        color: {
            description_pt: "",
            description_en: "",
            description_es: "",
            detailed_description_pt: "",
            detailed_description_en: "",
            detailed_description_es: "",
            active: true,
            geometry: 'squared',
            code: "",
            image_format: "",
            image_initial_date: moment().format('YYYY-MM-DD'),
            image_final_date: moment().add("50", 'years').format('YYYY-MM-DD'),
            image_orientation: "",
            finish_id: null,
            images: [],
            error: {
                description_pt: '',
                description_en: '',
                description_es: '',
                detailed_description_pt: '',
                detailed_description_en: '',
                detailed_description_es: '',
                active: '',
                code: '',
                image_format: '',
                image_initial_date: '',
                image_final_date: '',
                image_orientation: '',
                finish_id: '',
                images: []
            },
            valid: {
                description_pt: true,
                description_en: true,
                description_es: true,
                detailed_description_pt: true,
                detailed_description_en: true,
                detailed_description_es: true,
                active: true,
                code: true,
                image_format: true,
                image_initial_date: true,
                image_final_date: true,
                image_orientation: true,
                finish_id: true,
                images: []
            }
        }
}

/**
 * 
 * @param {Identificador da imagem} name 
 * @param {Attributo a ser setado um erro} attr 
 * @param {String do erro} error 
 * @param {Estado anterior} state 
 * 
 * Adiciona um erro em um campo especifico de uma imagem e retorna
 * o array de imagens ordenado na forma como estava
 */
const addErrorToImageField = (name, attr, error, state) => {
    let images = state.color.images.filter( (image) => image.name !== name)
    let index = state.color.images.findIndex( (image) => image.name === name)
    let oldImage = state.color.images[index]
    const image = {
        ...oldImage,
        error: {
            ...oldImage.error,
            [attr]: error
        },
        valid: {
            ...oldImage.valid,
            [attr]: (error === "") ? true : false
        }
    };
    images.splice(index, 0, image);
    return {
        ...state, 
        color: {
            ...state.color,
            images: [...images]
            //  [...images.splice(index, 0, )]
        },
    }
}

const changeAttrFromImage = (name, attr, value, state) => {
    let images = state.color.images.filter( (image) => image.name !== name)
    let index = state.color.images.findIndex( (image) => image.name === name)
    let image = state.color.images[index]
    image[attr] = value
    image.display = false
    images.splice(index, 0, image)
    return {
        ...state, 
        color: {
            ...state.color,
            images: images
        },
    }
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.RESET_COLORS:
            return {
                ...state,
                colors: []
            }
        case actionTypes.RESET_COLOR:
            return {
                ...state,
                color: initialState.color
            }
        case actionTypes.TOGGLE_ACTIVE:
            return {
                ...state,
                color: {
                    ...state.color,
                    active: !state.color.active
                }
            }
        case actionTypes.SET_IMAGES:
            return {
                ...state,
                color: {
                    ...state.color,
                    images: action.payload.images
                }
            }
        case actionTypes.RESET_IMAGES:
            return {
                ...state,
                color: {
                    ...state.color,
                    images: []
                }
            }

        case actionTypes.CHANGE_GEOMETRY:
            let value = action.payload.value
            let name = action.payload.name
            let imgs = []
            for(let x = 0; x < state.color.images.length; x++){
                imgs.push(
                    { 
                        ...state.color.images[x],
                        [name]: value
                    }
                )
            }
            return {
                ...state, 
                color: {
                    ...state.color,
                    geometry: value,
                    images: imgs
                }
            }
        case actionTypes.CHANGE_IMAGE_ATTR:
            return changeAttrFromImage(action.payload.name, action.payload.attr, action.payload.value, state);
        case actionTypes.ADD_IMAGES:
            return {
                ...state, 
                color: {
                    ...state.color,
                    images: state.color.images.concat(action.payload.images)
                }
            }
        case actionTypes.SET_IMAGE_ERROR:
            let newState = addErrorToImageField(action.payload.name, action.payload.attr, action.payload.error, state);
            return newState;
        case actionTypes.RESET_COLOR:
            return {
                ...state, 
                color: initialState.color
            }
        case actionTypes.SET_COLORS:
            return {
                ...state,
                colors: action.payload.colors
            }
        // case actionTypes.SET_COLOR:
        //     let {color} = action.payload
        //     let images = []
        //     for(let image of color.images){
                
        //         let name = image.image_orientation
        //         if(image.shadow === true){
        //             name+="_with_shadow"
        //         }
        //         images.push({
        //             ...imageFields,
        //             ...image,
        //             name: name,
        //             display: true
        //         });
        //     }
        //     return {
        //         ...state,
        //         color: {
        //             ...state.color,
        //             ...action.payload.color,
        //             images: images
        //         }
        //     }
        // case actionTypes.SET_ERROR:
        //     return {
        //         ...state,
        //         color: {
        //             ...state.color, 
        //             error: {
        //                 ...state.color.error,
        //                 [action.payload.name]: action.payload.error
        //             },
        //             valid: {
        //                 ...state.color.valid,
        //                 [action.payload.name]: (action.payload.error.length === 0 ? true : false)
        //             }
        //         }
        //     }
        case actionTypes.CHANGE_ATTR:
            return {
                colors: state.colors,
                color: {
                    ...state.color,
                    [action.payload.name]: action.payload.value    
                }
            }
        default:
            return state;
    }
}


/**
 * Como o objeto imagem deve portar
 * 
 */

export const imageFields = { 
        name: "",
        shadow: false,
        image_orientation: "",
        image_format: "",
        filename: "",
        file: "",
        error: {
            name: "",
            shadow: "",
            image_orientation: "",
            image_format: "",
            filename: "",
            file: "",
        },
        valid: {
            name: true,
            shadow: true,
            image_orientation: true,
            image_format: true,
            filename: true,
            file: true,
        }
}


export default reducer;
